import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./ScreenWriters.css";
import angleCircleRight from "../../img/angle-circle-right.png";
import rightChevron from "../../img/right-chevron.png";
import SWA_Award_Logo from "../../img/SWA-AWARD-LOGO-03 (2).png";
import info from "../../img/info.png";

function SwaOptionName() {
  const [hovered, setHovered] = useState(Array(7).fill(false));

  const handleMouseEnter = (index) => {
    const newHoveredState = Array(7).fill(false);
    newHoveredState[index] = true;
    setHovered(newHoveredState);
  };

  const handleMouseLeave = () => {
    setHovered(Array(7).fill(false));
  };

  const embossedStyle = {
    boxShadow:
      "inset 0 4px 6px rgba(255, 255, 255, 0.6), inset 0 -4px 6px rgba(0, 0, 0, 0.2)",
    transition: "transform 0.3s ease, box-shadow 0.3s ease",
    padding: "10px 20px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    width: "100%", // Adjust width as necessary
    justifyContent: "start",
    margin: "5px 0", // Spacing between buttons
    cursor: "pointer",
  };

  const missionStyle = {
    width: "100%",
    padding: "10px 20px",
    borderRadius: "10px",
    alignItems: "center",
    justifyContent: "start",
    margin: "5px 0px",
    cursor: "pointer",
  };
  // Option details including the path and label
  const options = [
    { path: "/mission", label: "Our Mission" },
    { path: "/mbc", label: "MBC" },
    { path: "/dsc", label: "DSC" },
    { path: "/downloads", label: "Downloads" },
    { path: "/welfare", label: "Welfare" },
    { path: "/archive", label: "Archive" },
    { path: "/faq", label: "FAQ" },
  ];

  return (
    <>
      <div className="welcome__section" style={{ color: "black" }}>
        <span
          style={{
            fontSize: "1.5rem",
            // fontStyle: "italic",
            fontWeight: "600",
            color: "#467fb3",
            fontFamily: "'Raleway', sans-serif",
            marginBottom: "0.5rem",
          }}
        >
          Welcome, Members!
        </span>
        <h1
          style={{
            fontSize: "2.5rem",
            fontFamily: "'Merriweather', 'Georgia', serif",
            fontWeight: "300",
          }}
        >
          Member Support
        </h1>

        {/* <Link to="/mission" className="blue__box">
          <div className="box__line  mission">
            <span>Our Mission</span>
          </div>
        </Link> */}
      </div>
      <hr style={{ width: "100%", marginTop: "1rem" }} />
      <div className="blue__box">
        {options.map((option, index) => (
          <Link
            to={option.path}
            key={index}
            style={{ textDecoration: "none" }}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="box__line" style={embossedStyle}>
              <img
                src={
                  hovered[index]
                    ? "./img/angle-circle-right.png"
                    : "./img/right-chevron.png"
                }
                alt="Icon"
              />
              <span>{option.label}</span>
            </div>
          </Link>
        ))}
      </div>

      <Link
        to="/awards"
        className="a  black__box"
        style={{ marginTop: "2.5rem", backgroundColor: "rgb(11,21,56)" }}
      >
        <img
          src={SWA_Award_Logo}
          alt="SWA AWARDS"
          style={{ borderRadius: "5px" }}
        />
        <div className="yellow__box">
          <span style={{ color: "#d3a221" }}>SWA AWARDS</span>
        </div>
      </Link>
    </>
  );
}

export default SwaOptionName;
